import { Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import formatNumber from '@/utils/formatNumber';

import getGoalCalculations from './utils/getGoalCalculations';
import useIsHistoricalData from './hooks/useIsHistoricalData';
import { goalShape } from './shapes';

function getBackgroundColor({ isComplete, isHistoricalData }) {
  if (isComplete) {
    return 'colorPool.emerald.500';
  }
  if (isHistoricalData) {
    return 'background.baseInverse';
  }
  return 'background.primaryMidnight';
}

export default function GoalTileHeader({ goal, useLargeBanner }) {
  const { availableSvu, awardedSvu, completionSvuValue } = goal;
  const { isComplete } = getGoalCalculations(goal);
  const isHistoricalData = useIsHistoricalData();

  // The cases below check if the SVU feature flags are turned off and render nothing if so.
  // FFs: show_goals_available_svu_homepage, show_goals_awarded_svu_homepage
  if (isHistoricalData) {
    if (!awardedSvu) return null;
  } else {
    if (isComplete && !awardedSvu) return null;
    if (!isComplete && !availableSvu) return null;
  }

  let completionSvuValueWidget = null;
  if (completionSvuValue) {
    if (isComplete) {
      completionSvuValueWidget = (
        <Typography
          color="colorPool.orange.50"
          variant="bodylargebold"
          whiteSpace="nowrap"
        >
          +{formatNumber(completionSvuValue)} additional SVU earned!
        </Typography>
      );
    } else if (!isHistoricalData) {
      completionSvuValueWidget = (
        <>
          <Typography
            color="colorPool.orange.100"
            variant="bodylargebold"
            whiteSpace="nowrap"
          >
            +{formatNumber(completionSvuValue)} SVU
          </Typography>{' '}
          for completing all
        </>
      );
    }
  }

  // If the completionSvuValueWidget is not null, we need to use the large banner.
  // We get useLargeBanner as a prop to force the large banner when we have multiple goal tiles where some
  // have multiline headers and some don't. This way we can ensure the height of the header is consistent.
  const shouldUseLargeBanner =
    useLargeBanner || completionSvuValueWidget !== null;

  const variant = completionSvuValueWidget ? 'bodysmall' : 'bodymedium';
  const boldVariant = completionSvuValueWidget
    ? 'bodysmallbold'
    : 'bodymediumbold';

  return (
    <Stack
      sx={{
        bgcolor: getBackgroundColor({ isComplete, isHistoricalData }),
        width: '100%',
        textAlign: 'center',
        height: shouldUseLargeBanner ? '70px' : '40px',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography
        component="span"
        variant={variant}
        color="text.primaryInverse"
        sx={{
          // to help with when the text is split into two lines, fitting it in the div nicely
          lineHeight: '0.5rem',
        }}
      >
        {isComplete || isHistoricalData ? (
          <>
            <Typography component="span" variant={boldVariant}>
              {formatNumber(awardedSvu)} SVU
            </Typography>{' '}
            {completionSvuValueWidget ? '' : 'earned'}
          </>
        ) : (
          <>
            Estimated
            <Typography component="span" variant={boldVariant}>
              {' '}
              {formatNumber(availableSvu)} SVU
            </Typography>{' '}
            potential
          </>
        )}
      </Typography>
      <Typography
        component="span"
        variant="bodysmall"
        color="text.primaryInverse"
        sx={{
          // to help with when the text is split into two lines, fitting it in the div nicely
          lineHeight: '0.5rem',
        }}
      >
        {completionSvuValueWidget}
      </Typography>
    </Stack>
  );
}

GoalTileHeader.propTypes = {
  goal: goalShape.isRequired,
  useLargeBanner: PropTypes.bool,
};

GoalTileHeader.defaultProps = {
  useLargeBanner: false,
};
