import PropTypes from 'prop-types';
import styled from 'styled-components';
import TableCell from '@mui/material/TableCell';

import { ReactComponent as ArrowDown } from '@/assets/icons/arrowDown.svg';
import { ReactComponent as ArrowUp } from '@/assets/icons/arrowUp.svg';
import ColumnHelp from '@/pages/TaskBasedWorkflow/components/ColumnHelp';

function SortableHeader({
  centered,
  currentDirection,
  currentField,
  onSort,
  sortField,
  title,
  className,
  dataPendoId,
  help,
}) {
  const isCurrentSortField = sortField === currentField;

  const getActiveArrow = (sortDirection) =>
    sortDirection === 'asc' ? (
      <ArrowWrapper $active>
        <ArrowUp />
      </ArrowWrapper>
    ) : (
      <ArrowWrapper $active>
        <ArrowDown />
      </ArrowWrapper>
    );

  const arrow = isCurrentSortField ? (
    getActiveArrow(currentDirection)
  ) : (
    <ArrowWrapper>
      <ArrowUp />
    </ArrowWrapper>
  );

  const textAlign = centered ? 'center' : 'left';

  const columnHelp = help ? (
    <ColumnHelp aria-label={`${title} help`} helpContent={help} />
  ) : null;

  return (
    <TableCellContainer
      sx={{ textAlign, cursor: 'pointer', whiteSpace: 'nowrap' }}
      className={className}
    >
      <StyledButton
        data-pendo-id={dataPendoId}
        type="button"
        onClick={() => onSort(sortField)}
      >
        {title}
      </StyledButton>
      {columnHelp}
      <button type="button" onClick={() => onSort(sortField)}>
        {arrow}
      </button>
    </TableCellContainer>
  );
}

SortableHeader.propTypes = {
  centered: PropTypes.bool,
  currentDirection: PropTypes.string.isRequired,
  currentField: PropTypes.string.isRequired,
  onSort: PropTypes.func.isRequired,
  sortField: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  dataPendoId: PropTypes.string,
  help: PropTypes.string,
};

SortableHeader.defaultProps = {
  centered: false,
  className: '',
  dataPendoId: undefined,
  help: undefined,
};

const TableCellContainer = styled(TableCell)``;

const StyledButton = styled.button`
  font-weight: ${(p) => p.theme.typography.fontWeightBold};
  color: ${(p) => p.theme.palette.text.label};
`;

const ArrowWrapper = styled.span`
  svg {
    color: ${(p) =>
      p.$active ? p.theme.palette.primary.main : 'rgba(0, 0, 0, 0)'};
    height: 22px;
    margin-bottom: 2px;
    margin-left: 4px;
    padding: 5px;
    vertical-align: middle;
    width: 22px;
  }

  ${TableCellContainer}:hover && svg {
    background: ${(p) => p.theme.palette.background.primaryLightest};
    border-radius: 22px;
    color: ${(p) => p.theme.palette.icon.link};
  }
`;

export default SortableHeader;
