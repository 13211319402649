/**
 * Palette Customization
 *
 * @type {import('@mui/material').PaletteOptions}
 * @deprecated These colors are deprecated
 *
 * Newly added fields should be added to the mui.d.ts file.
 *
 * Documentation: https://mui.com/customization/palette/
 *
 * WARNING: Before making changes to PaletteV2 please get explicit sign off
 * from design that the new/changed alias and/or color should happen. At time
 * of writing, that would be Kevin Lanuk. A new color or alias appearing in
 * Figma is not evidence enough that a new alias or color should be added to
 * the palette. It may be a mistake in Figma.
 */

/* PaletteV1 should probably not be getting any changes at this point. */
const paletteV1 = {
  background: {
    darker: '#f5f5f5',
    default: '#fafafa',
  },

  cta: {
    default: '#fc6237',
    hover: '#d14523',
  },

  dataViz: {
    qualitative6: '#ffa600',
  },

  font: {
    default: '#222529',
  },

  icon: {
    dark: '#72767b',
    default: '#999999',
  },

  input: {
    border: '#ced4da',
    disabled: '#e9ecef',
    primaryActive: '#0c73e9',
    secondaryActive: '#e0efff',
  },

  status: {
    danger: '#dc3545',
    success: '#55d307',
  },

  stellar: {
    primary: '#fd7e14',
    white: '#ffffff',
  },

  stroke: {
    dark: '#999999',
    light: '#e6e6e6',
  },
};

/* This is the current palette for our codebase. It is merged non-destructively
 * with PaletteV1 before export for the purposes of maintaining the code that
 * was already built on the old palette and which has not yet been updated to
 * use the new palette.
 *
 * This palette should be kept strictly in agreement with the colors and
 * aliases in figma, both in terms of aliases and hex values. If you think you
 * need to make a change to this palette it should be explicitly signed off by
 * design. At time of writing, that would be Kevin Lanuk. A new color or alias
 * appearing in Figma is not evidence enough that a new alias or color should
 * be added to the palette. It may be a mistake in Figma.
 *
 * Adding new colors to the v2 palette, please use a nested structure where appropriate. ie
 * token "DataViz/Categorical/Purple", should be accessible with palette.dataViz.categorical.purple
 * not palette.dataViz.categoricalPurple
 */
const paletteV2 = {
  background: {
    base: '#ffffff',
    baseInverse: '#2b2b36',
    primary: '#5b57ea',
    primaryDarker: '#484cd4',
    primaryDarkest: '#2839be',
    primaryLighter: '#d4d3ff',
    primaryLightest: '#ebebff',
    primaryMidnight: '#353785',
    secondary: '#f8f8ff',
    tableZebra: '#fbfbfe',
    transparent: '#ffffff00', // 0% opacity
  },

  border: {
    base: '#e3e3ee',
    disabled: '#bfbfca',
    input: '#71717d',
    primaryActive: '#5b57ea',
  },

  brand: {
    logo: '#ff6600',
  },

  dataViz: {
    categorical: {
      neutral300: '#bfbfca',
      neutral500: '#90929d',
      purple: '#484cd4',
    },
  },

  disabled: {
    disabled: '#bfbfca',
  },

  focus: {
    border: '#5b57ea',
    shadow: '#5b57ea7f', // 50% opacity
  },

  icon: {
    label: '#42424d',
    link: '#5b57ea',
    placeholder: '#bfbfca',
    primary: '#2b2b36',
    primaryInverse: '#ffffff',
    secondary: '#585864',
  },

  overlay: {
    overlay: '#2b2b367f', // 50% opacity
  },

  status: {
    errorBackground: '#ffe2e0',
    errorBackgroundHover: '#ffcccc',
    errorForeground: '#c42245',
    infoBackground: '#e0efff',
    infoBackgroundHover: '#a7e8fb',
    infoForeground: '#0a60c2',
    moderateBackground: '#fff686',
    moderateBackgroundHover: '#ffea61',
    moderateForeground: '#705b00',
    noneBackground: '#e3e3ee',
    noneBackgroundHover: '#bfbfca',
    noneForeground: '#585864',
    successBackground: '#d6fff3',
    successBackgroundHover: '#9dfbdf',
    successForeground: '#166e54',
    warningBackground: '#ffebdb',
    warningBackgroundHover: '#ffca9e',
    warningForeground: '#b8320a',
  },

  svu: {
    dark: '#12708c',
    lighter: '#a7e8fb',
    lightest: '#d6f6ff',
  },

  text: {
    label: '#42424d',
    link: '#5b57ea',
    placeholder: '#bfbfca',
    primary: '#2b2b36',
    primaryInverse: '#ffffff',
    secondary: '#585864',
  },
};

// There should not be any collisions here
const palette = {
  background: {
    ...paletteV1.background,
    ...paletteV2.background,
  },

  border: paletteV2.border,

  brand: paletteV2.brand,

  cta: paletteV1.cta,

  dataViz: {
    ...paletteV1.dataViz,
    ...paletteV2.dataViz,
  },

  disabled: paletteV2.disabled,

  focus: paletteV2.focus,

  font: paletteV1.font,

  icon: {
    ...paletteV1.icon,
    ...paletteV2.icon,
  },

  input: paletteV1.input,

  overlay: paletteV2.overlay,

  status: {
    ...paletteV1.status,
    ...paletteV2.status,
  },

  stellar: paletteV1.stellar,

  stroke: paletteV1.stroke,

  svu: paletteV2.svu,

  text: paletteV2.text,

  colorPool: {
    purple: {
      50: '#EBEBFF',
      500: '#8D85FF',
      600: '#5B57EA',
      700: '#484CD4',
      800: '#2839BE',
      900: '#0027A7',
      Midnight: '#353785',
    },
    yellow: {
      200: '#F8D421',
      600: '#7E6907',
    },
    red: {
      50: '#FFE2E0',
      100: '#FFCCCC',
      600: '#D73747',
      800: '#A90D2A',
    },
    emerald: {
      50: '#D6FFF3',
      500: '#26AB83',
      600: '#157F5F',
    },
    blue: {
      50: '#E0EFFF',
      200: '#99C6FA',
      600: '#0C73E9',
      700: '#0A60C2',
      800: '#004CA3',
    },
    orange: {
      50: '#FFEBDB',
      100: '#FFCA9E',
      300: '#FF9A47',
      500: '#F06C00',
      600: '#D43F11',
      700: '#B8320A',
    },
  },

  /**
   * Palette options from the default palette are below.
   *
   * We want to apply Stellar colors to MUI components, and it's easier to override the default palette
   * than it is to override each component to use the new palette above.
   */
  primary: {
    main: paletteV2.background.primary,
  },
  secondary: {
    main: paletteV2.text.secondary,
  },
  error: {
    main: paletteV2.status.errorForeground,
  },
  info: {
    main: paletteV2.status.infoForeground,
  },
} as const;

export default palette;
