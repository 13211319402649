import {
  Box,
  Button,
  Container,
  Stack,
  ThemeProvider,
  Typography,
} from '@mui/material';
import styled from 'styled-components';
import { useIntersection } from 'react-use';
import { useRef, useState } from 'react';
import { format } from 'date-fns';

import { ReactComponent as EditIcon } from '@/assets/icons/edit.svg';
// @ts-expect-error - js module
import { ButtonLabel } from '@/pages/PrintSummary/utils/printStyles';
// @ts-expect-error - js module
import ModalPatientDemographics from '@/pages/PatientSummary/components/ModalPatientDemographics';
// @ts-expect-error - js module
import useMutatePatientContactInfo from '@/pages/PatientSummary/hooks/useMutatePatientContactInfo';
import useFeatureFlag from '@/utils/useFeatureFlag';
// @ts-expect-error - js module
import themeV2 from '@/themeV2';
import { ReactComponent as HighNeedIcon } from '@/assets/icons/highNeed.svg';
import { Patient, VisitsDxHistoryData } from '@/utils/types';
// @ts-expect-error - js module
import { color, spacing } from '@/utils/stylingUtils';

// @ts-expect-error - js module
import isValidPriorDate from '../utils/isValidPriorDate';
// @ts-expect-error - js module
import useGetPriorDateView from '../hooks/useGetPriorDateView';

// @ts-expect-error - js module
import PatientSummaryBanner from './PatientSummaryBanner';
// @ts-expect-error - js module
import PaddedContainer from './PaddedContainer';
// @ts-expect-error - js module
import PatientDetailsButtons from './PatientDetailsButtons';

const intersectionOptions = {
  root: null,
  rootMargin: '0px',
  threshold: 1,
};

const Label = styled.span`
  color: ${color('text.secondary')};
`;

const PatientDetailWrapper = styled.div`
  white-space: nowrap;

  &:not(:last-of-type) {
    margin-right: ${spacing(3)};
  }
`;

const Chip = styled.div`
  background-color: ${color('background.base')};
  border-radius: 6px;
  padding: 3px 12px;
  white-space: nowrap;

  :not(:first-of-type) {
    margin-left: 4px;
  }
`;

const EditDetailsButton = styled.button`
  padding: 4px;
  line-height: 0;
  border: none;
  margin-left: 4px;
`;

const EditDetailsIcon = styled(EditIcon)`
  width: 16px;
  height: 16px;
  color: ${color('icon.link')};
`;

const StyledLink = styled.a`
  color: ${color('text.primaryInverse')};
  text-decoration: underline;
  &hover {
    color: ${color('text.primaryInverse')};
  }
`;

const StyledHighNeedIcon = styled(HighNeedIcon)`
  width: 32px;
  height: 32px;
  fill: white;
`;

const Pill = styled(Typography)`
  background-color: ${color('background.tableZebra')};
  color: ${color('colorPool.purple[700]')};
  border-radius: 4px;
  padding: 2px 6px;
  margin: 2px 5px;
  line-height: normal;
`;

type Props = {
  dateOfService: number | Date;
  patient: Patient;
  tags: Array<string>;
  headline: string;
  onShowSubmitModal: () => void;
  onCopyPatientSummary: () => void;
  onShowPatientNotesModal: () => void;
  submitDisabled: boolean;
  visitsDxHistoryData: VisitsDxHistoryData;
};

export default function PatientDetailsHighNeed({
  dateOfService,
  patient,
  tags,
  headline,
  onShowSubmitModal,
  onCopyPatientSummary,
  onShowPatientNotesModal,
  submitDisabled,
  visitsDxHistoryData,
}: Props) {
  const headerRef = useRef(null);
  const headerIntersection = useIntersection(headerRef, intersectionOptions);

  const actionsRef = useRef(null);
  const actionsIntersection = useIntersection(actionsRef, intersectionOptions);

  const showStickyActions =
    !actionsIntersection?.isIntersecting &&
    actionsIntersection?.boundingClientRect &&
    actionsIntersection.boundingClientRect.top < 0;

  const navigateToUrl = (url: string) => {
    window.location.href = url;
  };

  const [editDemoModalDisplayed, setEditDemoModalDisplayed] = useState(false);

  const { isLoading: isLoadingContactInfo, mutate: mutateContactInfo } =
    useMutatePatientContactInfo({
      patientId: patient.id,
    });

  const handleEditDemoConfirmed = (addressData: Record<string, string>) => {
    setEditDemoModalDisplayed(false);
    mutateContactInfo({ addressData });
  };

  const patientSummaryV2LinkToLegacyPs = useFeatureFlag(
    'patientSummaryV2LinkToLegacyPs',
  );
  const patientSummaryV2LinkToMockEhr = useFeatureFlag(
    'patientSummaryV2LinkToMockEhr',
  );
  const showMemberNumber = useFeatureFlag('showMemberNumberOnSummary');

  const formattedHeadline =
    headline?.length > 100 ? `${headline.substring(0, 100)}...` : headline;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { data: dateViews } = useGetPriorDateView(patient.id);
  const isViewingPriorDate = isValidPriorDate(dateOfService, dateViews);

  const showStickyHeader =
    headerIntersection &&
    !headerIntersection.isIntersecting &&
    headerIntersection.boundingClientRect.top < 0;

  return (
    <>
      <ModalPatientDemographics
        open={editDemoModalDisplayed}
        onSubmit={handleEditDemoConfirmed}
        onClose={() => setEditDemoModalDisplayed(false)}
        patient={patient}
      />
      <ThemeProvider theme={themeV2}>
        <Container
          sx={{
            color: 'text.primaryInverse',
            bgcolor: showStickyHeader ? 'colorPool.purple.800' : 'transparent',
            boxShadow: showStickyHeader ? 2 : 0,
            mt: 0,
            position: 'sticky',
            py: 0,
            // this makes showStickyHeader work
            top: '-1px',
            transition: 'box-shadow 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            zIndex: 1,
            padding: showStickyHeader
              ? '0px !important'
              : '0px 32px !important',
            // super important to keep the sticky from being glitchy
            height: '114px',
            // same as PaddedContainer.jsx
            minWidth: showStickyHeader ? '100%' : '900px',
            maxWidth: showStickyHeader ? '100%' : '1264px',
            alignItems: 'center',
          }}
          ref={headerRef}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              padding: '8px 24px',
              gap: '32px',
              borderRadius: '8px',
              marginTop: '24px',
              bgcolor: showStickyHeader
                ? 'transparent'
                : 'colorPool.purple.800',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: '8px',
              }}
            >
              <StyledHighNeedIcon />
              <Typography
                variant="bodysmall"
                sx={{ width: '117px', marginTop: '8px' }}
              >
                HIGH-NEED PATIENT
              </Typography>
              <Typography variant="bodysmall" sx={{ color: 'text.link' }}>
                <StyledLink href="/view-panel?patient_need[]=High-need%20Patient">
                  View all
                </StyledLink>
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                paddingTop: '8px',
                width: '100%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                }}
              >
                <Typography
                  sx={{ mr: 2, flex: '0 0 auto', whiteSpace: 'nowrap' }}
                  variant="headerlarge"
                >
                  {patient.firstName} {patient.lastName}
                </Typography>
                {visitsDxHistoryData?.risks?.map((risk) => (
                  <Pill key={risk}>{risk}</Pill>
                ))}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="headersmall"
                  flex="0 0 auto"
                  sx={{ paddingBottom: '16px' }}
                >
                  {patient.dateOfBirth}
                </Typography>
                {/* There are two PatientDetailsButtons elements.
              One of them lives in the sticky header and is visually hidden on page load.
              This one here is the one in sticky header */}
                <Box
                  sx={{
                    display: 'flex',
                    ml: 'auto',
                    justifyContent: 'space-between',
                    color: 'text.primaryInverse',
                  }}
                >
                  <PatientDetailsButtons
                    onShowSubmitModal={onShowSubmitModal}
                    onCopyPatientSummary={onCopyPatientSummary}
                    onShowPatientNotesModal={onShowPatientNotesModal}
                    patient={patient}
                    submitDisabled={submitDisabled}
                    visuallyHidden={!showStickyActions}
                    ddSyntheticsIdsDisabled
                    highNeedPatient
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
      <PaddedContainer>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexWrap: 'wrap',
            mt: 1,
            marginTop: '24px',
          }}
        >
          {showMemberNumber ? (
            <PatientDetailWrapper>
              <Label>MBR ID:</Label> {patient.memberNumber}
            </PatientDetailWrapper>
          ) : null}
          {patient.provider && (
            <PatientDetailWrapper>
              <Label>PCP:</Label> {patient.provider.name}
            </PatientDetailWrapper>
          )}
          {patient.attributedMgOffice && (
            <PatientDetailWrapper>
              <Label>Office:</Label> {patient.attributedMgOffice.name}
            </PatientDetailWrapper>
          )}
          {patient.plan && (
            <PatientDetailWrapper>
              <Label>Plan:</Label> {patient.plan.name}
            </PatientDetailWrapper>
          )}
          {patient.address && (
            <PatientDetailWrapper>
              <Label>Contact:</Label>{' '}
              {patient.address.phoneNumber ||
                patient.address.secondaryPhoneNumber}
            </PatientDetailWrapper>
          )}
          <EditDetailsButton
            aria-label="Edit contact information"
            onClick={() => setEditDemoModalDisplayed(true)}
            disabled={isLoadingContactInfo}
          >
            <EditDetailsIcon />
          </EditDetailsButton>
        </Box>

        {formattedHeadline ? (
          <Typography sx={{ my: 1 }} variant="h4">
            {formattedHeadline}
          </Typography>
        ) : null}

        <Stack
          direction="row"
          alignItems="center"
          flexWrap="wrap"
          gap={1}
          mt={1}
        >
          {tags.length > 0 ? (
            <Stack
              direction="row"
              flexWrap="wrap"
              p={1}
              borderRadius={2}
              sx={{
                backgroundColor: 'background.primaryLightest',
              }}
            >
              {tags.map((tag) => (
                <Chip key={tag}>{tag}</Chip>
              ))}
            </Stack>
          ) : null}

          {patientSummaryV2LinkToLegacyPs && (
            <Typography variant="h1">
              <Button
                variant="text"
                size="medium"
                sx={{ p: 1 }}
                onClick={() =>
                  navigateToUrl(`/patient/${patient.id}/summary?override=True`)
                }
              >
                <ButtonLabel>Patient Summary V1</ButtonLabel>
              </Button>
            </Typography>
          )}

          {patientSummaryV2LinkToMockEhr && (
            <Typography variant="h1">
              <Button
                variant="text"
                size="medium"
                sx={{ p: 1 }}
                onClick={() =>
                  navigateToUrl(`/patient/${patient.id}/summary-ehr-demo`)
                }
              >
                <ButtonLabel>Patient Summary Mock EHR</ButtonLabel>
              </Button>
            </Typography>
          )}

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              width: '100%',
            }}
          >
            {isViewingPriorDate ? (
              <Typography
                variant="h3"
                color="text.primary"
                align="right"
                sx={{ display: 'flex', alignSelf: 'flex-end' }}
              >
                Displaying patient summary for{' '}
                {format(dateOfService, 'MMMM d, yyyy')}
              </Typography>
            ) : null}
            {/* There are two PatientDetailsButtons elements.
              One of them lives in the sticky header and is visually hidden on page load. */}
            <PatientDetailsButtons
              onShowSubmitModal={onShowSubmitModal}
              onCopyPatientSummary={onCopyPatientSummary}
              onShowPatientNotesModal={onShowPatientNotesModal}
              patient={patient}
              ref={actionsRef}
              submitDisabled={submitDisabled}
            />
          </Box>
        </Stack>
        {patient.banner ? (
          <PatientSummaryBanner banner={patient.banner} />
        ) : null}
      </PaddedContainer>
    </>
  );
}
