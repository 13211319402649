import PropTypes from 'prop-types';
import { Box, Button, Stack, Typography, styled } from '@mui/material';

import { ReactComponent as TrendSame } from '@/assets/icons/trendSame.svg';

import GoalProgressAndTitle from './GoalProgressAndTitle';
import { goalShape } from './shapes';
import CountOfTotalCompleted from './CountOfTotalCompleted';
import { ReactComponent as CelebrationFlags } from './assets/celebrationFlags.svg';
import getGoalCalculations from './utils/getGoalCalculations';
import SubtypePrioritization from './SubtypePrioritization';
import useTilePendoId from './hooks/useTilePendoId';
import GoalTileHeader from './GoalTileHeader';
import useIsHistoricalData from './hooks/useIsHistoricalData';

export default function VerticalGoalTile({
  goal,
  isGoalsDashboard,
  sx,
  useLargeBanner,
}) {
  const { awardedSvu } = goal;
  const { buttonCopy, goalTitle, percentCompleted, isComplete, link } =
    getGoalCalculations(goal);
  const isHistoricalData = useIsHistoricalData();
  const pendoId = useTilePendoId();

  return (
    <Box
      sx={[
        {
          bgcolor: 'background.base',
          flexBasis: '0px',
          flexGrow: 1,
          borderRadius: 2,
          border: '1px solid',
          borderColor: 'background.base',
          overflow: 'hidden',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <GoalTileHeader goal={goal} useLargeBanner={useLargeBanner} />

      <Box
        sx={{
          pt: 3,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <GoalProgressAndTitle
          goalTitle={goalTitle}
          percentCompleted={percentCompleted}
          zIndex={isComplete ? 1 : 0}
          progressSizePx={88}
        />

        <Stack
          spacing={0.5}
          direction="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
          maxWidth="384px"
          sx={{
            px: { sm: 0.5, md: 1, lg: 3 },
            pb: 3,
          }}
        >
          <CountOfTotalCompleted
            percentCompleted={percentCompleted}
            completed={goal.completed}
            total={goal.total}
          />

          {Boolean(isGoalsDashboard && !isHistoricalData && !isComplete) && (
            <Typography variant="bodysmall">
              {awardedSvu || 0}{' '}
              <Box component="span" sx={{ fontSize: 9 }}>
                SVU claimed so far
              </Box>
            </Typography>
          )}

          {goal.subtypeFocus ? (
            <SubtypePrioritization goal={goal} />
          ) : (
            <Box height="24px" />
          )}

          {isHistoricalData ? null : (
            <Button
              variant={isComplete ? 'tertiary' : 'secondary'}
              size="small"
              sx={{
                width: '100%',
                px: 0,
              }}
              endIcon={<StyledTrendSame />}
              href={link}
              data-pendo-id={pendoId(
                goal.internalPerformanceMetric,
                'cta-button',
              )}
              data-dd-synthetics-id={`goals-${goal.internalPerformanceMetric}-cta-button`}
            >
              {isComplete ? 'Keep going' : buttonCopy}
            </Button>
          )}
          {!isGoalsDashboard && (
            <Button
              variant="tertiary"
              href="/goals_dashboard"
              sx={{ width: '100%' }}
              data-pendo-id={pendoId('more-details')}
            >
              <Typography variant="bodyxsmall">More details</Typography>
            </Button>
          )}
        </Stack>

        {isComplete && <StyledCelebrationFlags />}
      </Box>
    </Box>
  );
}

VerticalGoalTile.propTypes = {
  goal: goalShape.isRequired,
  isGoalsDashboard: PropTypes.bool,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  useLargeBanner: PropTypes.bool,
};

VerticalGoalTile.defaultProps = {
  isGoalsDashboard: false,
  sx: undefined,
  useLargeBanner: false,
};

const StyledTrendSame = styled(TrendSame)`
  width: 14px;
  height: 14px;
`;

const StyledCelebrationFlags = styled(CelebrationFlags)`
  position: absolute;
  top: -8px;
  z-index: 0;
  left: 50%;
  right: 50%;
  transform: translateX(-50%);
`;
