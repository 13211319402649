import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardActionArea,
  Typography,
  cardActionAreaClasses,
  css,
  styled,
} from '@mui/material';
import { first } from 'lodash';
import { useMount } from 'react-use';
import { useRef } from 'react';

import { userInputTaskShape } from '@/utils/transformFrontendUserInputTask';
import { useQueryParameters } from '@/contexts/QueryParametersContext';
import useGetPageMetadata from '@/pages/TaskBasedWorkflow/hooks/useGetPageMetadata';
import HideForNowIcon from '@/pages/TaskBasedWorkflow/components/HideForNowIcon';
import { ReactComponent as FlagIcon } from '@/assets/icons/flag.svg';
import useFeatureFlag from '@/utils/useFeatureFlag';
import { ReactComponent as HighNeedIcon } from '@/assets/icons/highNeed.svg';
import PatientNeedTooltip from '@/components/PatientNeedTooltip';

import { HIGH_NEED_PATIENT, TRANSITION_TIMEOUT } from '../../constants';
import PriorityScoreBadge from '../PriorityScoreBadge';

function getNextStepLabel(preferredNextStep, stepLabelConfiguration) {
  return (
    stepLabelConfiguration[preferredNextStep.templateStep] ||
    preferredNextStep.description
  );
}

export default function TaskPanelCard({ sx, task }) {
  const {
    parameters: { stepId, taskId },
    mergeParameters,
  } = useQueryParameters();
  const { action, patient } = task;
  const preferredNextStep = first(action.v2Dto.preferredPath);
  const handleClick = () => {
    mergeParameters({
      actionId: action.id,
      taskId: task.id,
      patientId: patient.id,
      // Update the step execution panel only if it's expanded.
      stepId: stepId ? preferredNextStep?.stepId : undefined,
    });
  };
  const active = taskId === task.id;
  const rootRef = useRef(null);

  useMount(() => {
    // If the card is active on page load, scroll it into view.
    if (active) {
      rootRef?.current?.scrollIntoView({
        // Vertically center the element when scrolling to it.
        block: 'center',
      });
    }
  });

  const isHighNeedPatientUxEnabled = useFeatureFlag(
    'enable_high_need_patients_ux',
  );
  const isHighNeedPatient =
    isHighNeedPatientUxEnabled && patient.patientNeed === HIGH_NEED_PATIENT;

  const { isLoading: isLoadingMetadata, data: metadata } = useGetPageMetadata();
  if (isLoadingMetadata) return null;
  const nextStepLabel = getNextStepLabel(
    preferredNextStep,
    metadata.stepLabelConfiguration,
  );

  const { hiddenForNow: showHiddenIcon, isFlagged: showFlaggedIcon } = task;

  return (
    <TaskPanelCardRoot $active={active} sx={sx} ref={rootRef}>
      <StyledCardActionArea disableRipple onClick={handleClick}>
        <Box alignSelf="flex-start" display="flex" flexDirection="column">
          <PriorityScoreBadge abbreviated score={task.score} />
        </Box>

        <Typography
          component="div"
          variant="bodymedium"
          sx={{ flexGrow: 1, ml: 1 }}
        >
          <div style={{ display: 'flex' }}>
            <Typography variant={active ? 'bodymediumbold' : 'bodymedium'}>
              {action.v2Dto.description}
            </Typography>
            <Box ml="auto" whiteSpace="nowrap">
              {showHiddenIcon ? <HideForNowIcon /> : null}
              {showFlaggedIcon ? <StyledFlagIcon /> : null}
            </Box>
          </div>

          <Box color="text.secondary" data-dd-action-name="Patient Name">
            {patient.lastName}, {patient.firstName}
            {isHighNeedPatient ? (
              <PatientNeedTooltip>
                <StyledHighNeedIcon />
              </PatientNeedTooltip>
            ) : null}
          </Box>

          <Box color="text.secondary">{nextStepLabel}</Box>
        </Typography>
        <ActiveBorder $active={active} />
      </StyledCardActionArea>
    </TaskPanelCardRoot>
  );
}
TaskPanelCard.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  task: userInputTaskShape.isRequired,
};

TaskPanelCard.defaultProps = {
  sx: undefined,
};

const TaskPanelCardRoot = styled(Card)`
  background-color: transparent;
  border-radius: 0;
  box-shadow: none;
  border-bottom: 1px solid ${(p) => p.theme.palette.border.base};
  transition: background-color ${TRANSITION_TIMEOUT}ms;

  ${(p) =>
    p.$active &&
    css`
      background-color: ${p.theme.palette.background.base};
    `}
`;

const StyledCardActionArea = styled(CardActionArea)`
  display: flex;
  padding: ${(p) => p.theme.spacing(1, 2)};

  :focus {
    // Remove focus outline
    box-shadow: none;
  }

  .${cardActionAreaClasses.focusHighlight} {
    display: none;
  }
`;

const ActiveBorder = styled('span')`
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  border-left: ${(p) => p.theme.spacing(1)} solid;
  border-color: transparent;
  height: 100%;
  pointer-events: none;
  transition: border-color ${TRANSITION_TIMEOUT}ms;

  ${(p) =>
    p.$active &&
    css`
      border-color: ${p.theme.palette.border.primaryActive};
    `}
`;

const StyledFlagIcon = styled(FlagIcon)`
  height: 13px;
  width: 16px;
  margin-left: ${(p) => p.theme.spacing(1)};
  color: ${(p) => p.theme.palette.background.primary};
`;

const StyledHighNeedIcon = styled(HighNeedIcon)`
  width: 16px;
  height: 16px;
  vertical-align: sub;
  margin-left: ${(p) => p.theme.spacing(0.5)};
  fill: ${(p) => p.theme.palette.colorPool.purple[700]};
`;
