export enum InternalPerformanceMetric {
  hcc = 'HCC',
  acv = 'ACV',
  qualityC = 'Quality C',
  qualityD = 'Quality D',
}

export type SubtypeGoal = {
  completed: number;
  total: number;
  subtype: string;
  subtypeDisplay: string;
  awardedSvu: number;
};

export type Goal = {
  internalPerformanceMetric: InternalPerformanceMetric;
  completed: number;
  total: number;
  availableSvu: number;
  awardedSvu: number;
  completionSvuValue: number;
  subtypeGoals: Array<SubtypeGoal>;
  subtypeFocus: string;
};
