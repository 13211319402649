import PropTypes from 'prop-types';
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  styled,
  tableCellClasses,
  tableSortLabelClasses,
} from '@mui/material';

import { userInputTaskShape } from '@/utils/transformFrontendUserInputTask';
import { useQueryParameters } from '@/contexts/QueryParametersContext';
import ColumnHelp from '@/pages/TaskBasedWorkflow/components/ColumnHelp';
import useFeatureFlag from '@/utils/useFeatureFlag';
import useGetUser from '@/hooks/useGetUser';
import { formatDateTime } from '@/utils/formatDateTime';
import useDate from '@/hooks/useDate';
import ActionMostRecentProviderNameForDisplay from '@/pages/TaskBasedWorkflow/components/taskTableView/ActionMostRecentProviderNameForDisplay';
import CompleteSelectedTasksButton from '@/pages/TaskBasedWorkflow/components/taskTableView/completedTasks/CompleteSelectedTasksButton';
import { SelectedTasksProvider } from '@/pages/TaskBasedWorkflow/contexts/SelectedTasksContext';
import EhrVisitStatus from '@/pages/TaskBasedWorkflow/components/taskTableView/EhrVisitStatus';
import ParentActionDos from '@/pages/TaskBasedWorkflow/components/taskTableView/ParentActionDos';
import TocSummaryBanner from '@/pages/TaskBasedWorkflow/components/taskTableView/TocSummaryBanner';
import LastOutreachDate from '@/pages/TaskBasedWorkflow/components/taskTableView/LastOutreachDate';

import useSelectTask from '../hooks/useSelectTask';
import {
  DEFAULT_SORT_DIRECTION,
  DEFAULT_SORT_FIELD,
  Page,
  SortDirection,
} from '../constants';
import useGetPageMetadata from '../hooks/useGetPageMetadata';
import { usePage } from '../contexts/PageContext';

import { ReactComponent as CaretDownIcon } from './taskTableView/caretDown.svg';
import PatientNameDob from './taskTableView/PatientNameDob';
import TaskPriorityScore from './taskTableView/TaskPriorityScore';
import ActionDescription from './taskTableView/ActionDescription';
import ActionCompleteBy from './taskTableView/ActionCompleteBy';
import ActionPreferredNextStep from './taskTableView/ActionPreferredNextStep';
import TaskFlagged from './taskTableView/TaskFlagged';
import ActionSvu from './taskTableView/ActionSvu';
import ShowMoreButton from './taskTableView/ShowMoreButton';
import TaskDischargeDate from './taskTableView/TaskDischargeDate';
import TaskFacilityName from './taskTableView/TaskFacilityName';
import TaskScheduledVisit from './taskTableView/TaskScheduledVisit';
import PYMSMedicationName from './taskTableView/PYMSMedicationName';
import PYMSAllowableDaysLeft from './taskTableView/PYMSAllowableDaysLeft';
import PYMSRenewalDueDate from './taskTableView/PYMSRenewalDueDate';
import ExpectedPickupDate from './taskTableView/ExpectedPickupDate';
import PYMSAbsoluteFailDate from './taskTableView/PYMSAbsoluteFailDate';
import SummaryBanner from './taskTableView/SummaryBanner';
import PcpVisitDateOfService from './taskTableView/PcpVisitDateOfService';
import ExportPrintBar from './ExportPrintBar';
import ActionTestType from './taskTableView/ActionTestType';
import LastVisit from './taskTableView/LastVisit';
import AppointmentDate from './taskTableView/AppointmentDate';
import OutreachStatus from './taskTableView/OutreachStatus';
import PatientNeed from './taskTableView/PatientNeed';

// Names of columns displayed in the table. The values must be kept in sync with TbwColumnName in the backend.
const ColumnName = {
  actionDescription: 'action_description',
  patientNameDob: 'patient_name_dob',
  priority: 'priority',
  svu: 'svu',
  actionCompleteBy: 'action_complete_by',
  preferredNextStep: 'preferred_next_step',
  flagged: 'is_flagged',
  dischargeDate: 'discharge_date',
  facilityName: 'facility_name',
  scheduledVisit: 'scheduled_visit',
  absoluteFailDate: 'pyms_absolute_fail_date',
  adherenceDaysLeft: 'pyms_allowable_days_remaining_no_lag',
  renewalDueDate: 'pyms_renewal_due_date',
  expectedPickupDate: 'expected_pickup_date',
  medicationName: 'pyms_medication_name',
  pcpVisitDateOfService: 'pcp_visit_date_of_service',
  testType: 'test_type',
  mostRecentProviderNameForDisplay: 'most_recent_provider_name_for_display',
  lastVisit: 'last_visit',
  appointmentDate: 'appointment_date',
  ehrVisitStatus: 'ehr_visit_status',
  parentActionDos: 'parent_date_of_service',
  outreachStatus: 'outreach_status',
  patientNeed: 'patient_need',
  lastOutreachDate: 'last_outreach_date',
};

function getCellAlignment(columnName) {
  switch (columnName) {
    case ColumnName.svu:
      return 'right';
    case ColumnName.priority:
    case ColumnName.flagged:
    case ColumnName.outreachStatus:
    case ColumnName.patientNeed:
      return 'center';
    case ColumnName.actionCompleteBy:
    case ColumnName.preferredNextStep:
    case ColumnName.absoluteFailDate:
    case ColumnName.adherenceDaysLeft:
    case ColumnName.renewalDueDate:
    case ColumnName.parentActionDos:
    case ColumnName.ehrVisitStatus:
      return 'left';
    default:
      // Use the table's default alignment
      return undefined;
  }
}

const componentMapping = {
  [ColumnName.actionDescription]: ActionDescription,
  [ColumnName.patientNameDob]: PatientNameDob,
  [ColumnName.priority]: TaskPriorityScore,
  [ColumnName.svu]: ActionSvu,
  [ColumnName.actionCompleteBy]: ActionCompleteBy,
  [ColumnName.preferredNextStep]: ActionPreferredNextStep,
  [ColumnName.flagged]: TaskFlagged,
  [ColumnName.facilityName]: TaskFacilityName,
  [ColumnName.dischargeDate]: TaskDischargeDate,
  [ColumnName.scheduledVisit]: TaskScheduledVisit,
  [ColumnName.absoluteFailDate]: PYMSAbsoluteFailDate,
  [ColumnName.adherenceDaysLeft]: PYMSAllowableDaysLeft,
  [ColumnName.renewalDueDate]: PYMSRenewalDueDate,
  [ColumnName.expectedPickupDate]: ExpectedPickupDate,
  [ColumnName.medicationName]: PYMSMedicationName,
  [ColumnName.pcpVisitDateOfService]: PcpVisitDateOfService,
  [ColumnName.testType]: ActionTestType,
  [ColumnName.mostRecentProviderNameForDisplay]:
    ActionMostRecentProviderNameForDisplay,
  [ColumnName.lastVisit]: LastVisit,
  [ColumnName.appointmentDate]: AppointmentDate,
  [ColumnName.ehrVisitStatus]: EhrVisitStatus,
  [ColumnName.parentActionDos]: ParentActionDos,
  [ColumnName.outreachStatus]: OutreachStatus,
  [ColumnName.patientNeed]: PatientNeed,
  [ColumnName.lastOutreachDate]: LastOutreachDate,
};

/**
 * Given a columnName, returns which React component to render.
 *
 * @param {string} columnName
 */
function componentMapper(columnName) {
  return componentMapping[columnName] ?? null;
}

function getCellContents(config, { patient, action, task, rowIndex }) {
  const Component = componentMapper(config.columnName);
  return Component ? (
    <Component
      patient={patient}
      action={action}
      task={task}
      rowIndex={rowIndex}
    />
  ) : null;
}

export default function TaskTableView({
  className,
  printPreview,
  tasks,
  hasNextPage,
  onClickShowMore,
  isFetchingNextPage,
  sx,
}) {
  const page = usePage();
  const {
    parameters: {
      sortField = DEFAULT_SORT_FIELD,
      sortDirection = DEFAULT_SORT_DIRECTION,
    },
    mergeParameters,
  } = useQueryParameters();
  const selectTask = useSelectTask();
  const tbwExportPrintEnabled = useFeatureFlag('tbw_export_print_enabled');
  const autoCloseAVsEnabled = useFeatureFlag('autoclose_avs');
  const enableLastOutreachMedsColumn = useFeatureFlag(
    'enable_last_outreach_meds_column',
  );

  const date = useDate();

  const { isInitialLoading: isInitialLoadingMetadata, data: metadata } =
    useGetPageMetadata({
      // This data is not used in the print preview
      enabled: !printPreview,
    });
  const { data: user, isInitialLoading: isInitialLoadingUser } = useGetUser({
    enabled: printPreview,
  });

  const handleClickSort = (newSortField) => {
    mergeParameters({
      sortField: newSortField,
      sortDirection:
        sortField !== newSortField || sortDirection !== DEFAULT_SORT_DIRECTION
          ? DEFAULT_SORT_DIRECTION
          : // Assume desc is the default sort direction
            SortDirection.asc,
    });
  };

  const handleClickTaskRow = (task) => {
    selectTask(task);
  };

  const BannerRootComponent =
    page === Page.transitionOfCare
      ? (props) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <TocSummaryBanner {...props} data-testid="toc-banner" />
        )
      : (props) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <SummaryBanner data-testid="summary-banner" {...props} />
        );
  if (isInitialLoadingMetadata || isInitialLoadingUser) return null;

  return (
    <SelectedTasksProvider tasks={tasks}>
      <TableViewRoot className={className} sx={sx}>
        {!printPreview && metadata?.bannerTitle ? (
          <BannerRootComponent
            sx={{ mb: 2 }}
            title={metadata.bannerTitle}
            text={metadata.bannerText}
            firstTask={tasks && tasks.length > 0 ? tasks[0] : null}
          />
        ) : null}

        {page !== Page.tasks && (
          <Stack
            direction="row"
            width="100%"
            mb={1}
            spacing={1}
            justifyContent="flex-end"
          >
            {autoCloseAVsEnabled ? (
              <CompleteSelectedTasksButton tasks={tasks} />
            ) : null}
            {!printPreview && tbwExportPrintEnabled ? <ExportPrintBar /> : null}
          </Stack>
        )}

        <TableWrapper>
          <StyledTable>
            <TableHead>
              <TableRow>
                {metadata.columnConfigurations.map((config) => (
                  <StyledTableCell
                    key={config.columnName}
                    align={getCellAlignment(config.columnName)}
                    sx={{
                      flexDirection: 'row-reverse',
                      whiteSpace: 'nowrap',
                    }}
                    data-pendo-id={`${config.columnName}-col-header`}
                  >
                    <StyledTableSortLabel
                      active={sortField === config.sortField}
                      IconComponent={CaretDownIcon}
                      hideSortIcon
                      direction={sortDirection}
                      onClick={
                        config.sortable
                          ? () => handleClickSort(config.sortField)
                          : undefined
                      }
                      sx={{ cursor: config.sortable ? undefined : 'default' }}
                    >
                      {config.label}
                    </StyledTableSortLabel>
                    {config.help ? (
                      <ColumnHelp
                        aria-label={`${config.label} help`}
                        helpContent={config.help}
                      />
                    ) : null}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>

            {hasNextPage && !printPreview ? (
              <TableFooter>
                <TableRow>
                  <TableCell
                    colSpan={100 /* Some arbitrarily large number */}
                    align="center"
                  >
                    <ShowMoreButton
                      onClick={onClickShowMore}
                      isFetchingNextPage={isFetchingNextPage}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            ) : null}

            {printPreview ? (
              <TableFooter>
                <TableRow>
                  <TableCell
                    colSpan={100 /* Some arbitrarily large number */}
                    align="center"
                  >
                    Printed by {user.name} on {formatDateTime(date)}
                  </TableCell>
                </TableRow>
              </TableFooter>
            ) : null}

            <TableBody>
              {(() => {
                const localSubtypeCountMap = {};
                return tasks.map((task, rowIndex) => {
                  const { subtype } = task.action;
                  const currentCount = localSubtypeCountMap[subtype] ?? 0;
                  localSubtypeCountMap[subtype] = currentCount + 1;
                  return (
                    <StyledTableRow
                      data-dd-synthetics-id={
                        rowIndex === 0 ? 'tbw-first-row' : undefined
                      }
                      data-pendo-id={`task-row-index-${rowIndex}-${subtype}-index-${currentCount}`}
                      key={task.id}
                      onClick={() => handleClickTaskRow(task)}
                    >
                      {metadata.columnConfigurations.map((config) => {
                        const { action, patient } = task;
                        const cellClassName = enableLastOutreachMedsColumn
                          ? `${page}-${config.columnName}-column`
                          : '';
                        return (
                          <StyledTableCell
                            key={config.columnName}
                            align={getCellAlignment(config.columnName)}
                            className={cellClassName}
                          >
                            {getCellContents(config, {
                              task,
                              action,
                              patient,
                              rowIndex,
                            })}
                          </StyledTableCell>
                        );
                      })}
                    </StyledTableRow>
                  );
                });
              })()}
            </TableBody>
          </StyledTable>
        </TableWrapper>
      </TableViewRoot>
    </SelectedTasksProvider>
  );
}

TaskTableView.propTypes = {
  className: PropTypes.string,
  hasNextPage: PropTypes.bool,
  printPreview: PropTypes.bool,
  isFetchingNextPage: PropTypes.bool,
  onClickShowMore: PropTypes.func,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  tasks: PropTypes.arrayOf(userInputTaskShape).isRequired,
};

TaskTableView.defaultProps = {
  className: undefined,
  printPreview: false,
  hasNextPage: false,
  isFetchingNextPage: false,
  onClickShowMore: undefined,
  sx: undefined,
};

const TableViewRoot = styled('div')(({ theme }) => ({
  padding: theme.spacing(4, 3),

  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
  },
  [theme.breakpoints.up('xl')]: {
    maxWidth: theme.breakpoints.values.xl,
    margin: '0 auto',
  },
}));

const TableWrapper = styled('div')`
  background-color: ${(p) => p.theme.palette.background.base};
  border: 1px solid;
  border-color: ${(p) => p.theme.palette.border.base};
  border-radius: ${(p) => 2 * p.theme.shape.borderRadius}px;
  th:first-of-type {
    border-top-left-radius: 10px;
  }
  th:last-of-type {
    border-top-right-radius: 10px;
  }
  tr:last-of-type td:first-of-type {
    border-bottom-left-radius: 10px;
  }
  tr:last-of-type td:last-of-type {
    border-bottom-right-radius: 10px;
  }
  td.tbw_medication_management-patient_name_dob-column,
  td.tbw_medication_management-last_outreach_date-column,
  td.tbw_medication_management-patient_need-column {
    background-color: ${(p) => p.theme.palette.background.tableZebra};
  }
`;

const StyledTable = styled(Table)`
  // Remove the bottom-border of the last row of the table
  tfoot tr:last-child td,
  // tbody is only adjacent to thead if there is no footer
  thead + tbody tr:last-child td {
    border-bottom: none;
  }
`;

const StyledTableSortLabel = styled(TableSortLabel)`
  .${tableSortLabelClasses.icon} {
    margin-left: 0;
  }
`;

const StyledTableRow = styled(TableRow)`
  cursor: pointer;

  &:hover {
    background-color: ${(p) => p.theme.palette.background.tableZebra};
  }
`;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  ...theme.typography.bodymedium,
  padding: theme.spacing(1),

  [`&.${tableCellClasses.head}`]: {
    ...theme.typography.bodysmallbold,
  },
}));
