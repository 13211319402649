// Priority enums of UserInputTask objects
export const Priority = {
  urgent: 'Urgent',
  veryHigh: 'Very High',
  high: 'High',
  moderate: 'Moderate',
  standard: 'Standard',

  /** @deprecated: Use `standard` instead for MTL pages */
  average: 'Average',
} as const;

// The current page we're on. The values must match the urls of the TBW pages.
// You only need to add an enum here if you're using it to enable page-specific features.
export const Page = {
  medicationManagement: 'tbw_medication_management',
  chartUpload: 'chart_upload',
  visits: 'annual_visits',
  transitionOfCare: 'transition-of-care',
  priorityPatient: 'priority-patient',
  patientExperience: 'patient-experience',
  programEnrollment: 'program-enrollment',
  qualityScreenings: 'quality-screenings',

  tasks: 'tasks', // The Single Task List page, which shows all action subtypes
} as const;
// Action types

export const ANNUAL_VISIT = 'annual_visit';
export const COORDINATE = 'coordinate';

// Action subtypes
export const CHART_UPLOAD = 'chart_upload';

export const RX_RENEWAL_RASA = 'rx_renewal_rasa';
export const RX_RENEWAL_DIABETES = 'rx_renewal_diabetes';
export const RX_RENEWAL_STATINS = 'rx_renewal_statins';

export const TOC_CONTACT_PATIENT = 'toc_contact_patient';
export const TOC_MEDICATION_RECONCILIATION = 'toc_medication_reconciliation';
export const TOC_SCHEDULE_VISIT = 'toc_schedule_visit';
export const ED_TOC_CONTACT_PATIENT = 'ed_toc_contact_patient';
export const TOC_NOTIFICATION_OF_ADMISSION = 'toc_notification_of_admission';
export const TOC_NOTIFICATION_OF_DISCHARGE = 'toc_notification_of_discharge';
export const TOC_ED_FOLLOW_UP_FMC = 'toc_ed_follow_up_fmc';
export const TOC_ED_FOLLOW_UP_FUM = 'toc_ed_follow_up_fum';
export const TOC_ED_FOLLOW_UP_FUA = 'toc_ed_follow_up_fua';

export const PRIORITY_PATIENT_VISIT = 'priority_patient_visit';

export const PROGRAM_ENROLLMENT = 'program_enrollment';

export const VISIT_FOLLOW_UP_PX = 'visit_follow_up_px';

export const PX_HELPERS_COORD_CARE = 'px_helpers_coord_care';

export const PX_HELPERS_COORD_MEDS = 'px_helpers_coord_meds';

export const RX_PICKUP_RASA = 'rx_pickup_rasa';
export const RX_PICKUP_STATINS = 'rx_pickup_statins';
export const RX_PICKUP_DIABETES = 'rx_pickup_diabetes';
export const RX_PICKUP_SUBTYPES = [
  RX_PICKUP_RASA,
  RX_PICKUP_STATINS,
  RX_PICKUP_DIABETES,
];

export const RX_EXTEND_DAYS_SUPPLY_RASA = 'rx_extend_days_supply_rasa';
export const RX_EXTEND_DAYS_SUPPLY_STATINS = 'rx_extend_days_supply_statins';
export const RX_EXTEND_DAYS_SUPPLY_DIABETES = 'rx_extend_days_supply_diabetes';
export const RX_EXTEND_SUBTYPES = [
  RX_EXTEND_DAYS_SUPPLY_RASA,
  RX_EXTEND_DAYS_SUPPLY_STATINS,
  RX_EXTEND_DAYS_SUPPLY_DIABETES,
];

export const ActionStep = {
  completeVisit: 'complete_visit',
  needToReschedule: 'need_to_reschedule',
  rescheduleVisit: 'reschedule_visit',
  scheduleVisit: 'schedule_visit',
  scheduleBCS: 'schedule_bcs',
  scheduleCCS: 'schedule_ccs',
  scheduleCHL: 'schedule_chl',
  scheduleCOLO: 'schedule_colo',
  scheduleDEE: 'schedule_dee',
  scheduleOSTEO: 'schedule_osteo',
  versionSwitchBToB: 'version_switch_B_to_B',
} as const;

// steps
export const UPDATE_ELIGIBILITY_WINDOW = 'update_eligibility_window';
export const RX_PICKUP_CONTACT_PATIENT_AFTER_REOPENED =
  'contact_patient_after_reopened';

// The duration of transitions in milliseconds;
export const TRANSITION_TIMEOUT = 150;

// The sortable fields for search. The values should be kept in sync with the backend.
export const SortField = {
  priority: 'priority',
  flagged: 'is_flagged',
  actionDateOfService: 'action_date_of_service',
} as const;

/**
 * Filter tab enums. Must be kept in sync with TbwFilterTab in the backend.
 *
 * These are NOT the same as preferred_next_step or template_step.
 */
export const FilterTab = {
  allIncomplete: 'all',
  notScheduled: 'not_scheduled',
  upcomingAppt: 'upcoming_appt',
  readyToComplete: 'ready_to_complete',
  externalUpdate: 'external_update',
} as const;

// The sortable directions for search. The values should be kept in sync with the backend.
export const SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

// The default sort parameters for the search. They should be kept in sync with the backend.
export const DEFAULT_SORT_DIRECTION = SortDirection.desc;
export const DEFAULT_SORT_FIELD = SortField.priority;

// Measurements of the legacy navbar
export const LEGACY_NAVBAR_NOWRAP_HEIGHT = 65;
export const LEGACY_NAVBAR_WRAP_HEIGHT = 130;
// The max width that the universal search bar stops wrapping.
export const LEGACY_NAVBAR_MAX_WRAP_BREAKPOINT = 1600;

export const OUTREACH_CAMPAIGN_STATUS_ACTIVE = 'active';
export const OUTREACH_CAMPAIGN_STATUS_FAILED = 'failed';
export const OUTREACH_CAMPAIGN_STATUS_SUCCESSFUL = 'successful';
export const OUTREACH_CAMPAIGN_STATUS_CANCELLED = 'cancelled';

export const HIGH_NEED_PATIENT = 'High-need Patient';
