// @ts-expect-error - js module
import { actionShape } from '@/utils/transformFrontendAction';
import useGetContextualInfo from '@/pages/TaskBasedWorkflow/hooks/useGetContextualInfo';
// @ts-expect-error - js module
import FieldSkeleton from '@/pages/TaskBasedWorkflow/components/taskTableView/FieldSkeleton';
import { Action } from '@/utils/types';

export default function LastOutreachDate({ action }: { action: Action }) {
  const { data: contextualInfo, isSuccess } = useGetContextualInfo(action);

  return !isSuccess ? (
    <FieldSkeleton />
  ) : (
    <span>
      {contextualInfo.lastOutreachDate === '--'
        ? 'none in last 30 days'
        : contextualInfo.lastOutreachDate}
    </span>
  );
}

LastOutreachDate.propTypes = {
  action: actionShape.isRequired,
};
