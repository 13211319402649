import { Alert } from '@mui/material';
import Typography from '@mui/material/Typography';

function RxPickupReopenedAlert() {
  return (
    <Alert severity="info" sx={{ marginTop: '16px', marginBottom: '16px' }}>
      <Typography>
        It was previously indicated that the patient planned to get this
        medication, but the payer still hasn&apos;t received a pharmacy claim
        confirming pickup. Consider following up with the patient one more time.
      </Typography>
    </Alert>
  );
}

export default RxPickupReopenedAlert;
